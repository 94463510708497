<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：行政管理 > <b>公章登记</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <el-select
                            v-model="seal_id"
                            clearable
                            placeholder="请选择用章类型"
                    >
                        <el-option
                                v-for="item in sealOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select
                            v-model="society"
                            clearable
                            placeholder="请选择所属学会"
                    >
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-date-picker
                            style="width: 260px"
                            @change="timeChange"
                            v-model="start_end_time"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            start-placeholder="申请开始日期"
                            end-placeholder="申请结束日期">
                    </el-date-picker>
                    <el-input style="width: 230px" v-model="search_content" placeholder="请输入关键字"></el-input>
                    <el-button type="primary" @click="search">搜索</el-button>
                </div>
                <!--<router-link to="/public">-->
                <el-button type="primary" @click="tableExport">导出</el-button>
                <!--</router-link>-->
            </div>
            <div class="project-list">
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                        <template slot-scope="scope">
                            <div class="table-id">
                                {{scope.row.id}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="申请时间"
                            width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.add_time}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="用印申请人"
                            width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.from_name}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="所属学会">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.society}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="用章类型"
                            width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.seal_type}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="用途说明">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.content}}
                            </div>
                        </template>
                    </el-table-column>
                    <!--<el-table-column-->
                    <!--label="申请人签字"-->
                    <!--width="110">-->
                    <!--<template slot-scope="scope">-->
                    <!--<div class="table-item">-->
                    <!--{{scope.row.sign}}-->
                    <!--</div>-->
                    <!--</template>-->
                    <!--</el-table-column>-->
                    <el-table-column
                            prop="operate"
                            label="备注说明"
                            width="300">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{scope.row.remark}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "../../config/index"

    export default {
        name: "list",
        data() {
            return {
                start_end_time: [],
                search_content: '',
                tableData: [],
                page: 1,
                pageTotal: 1,
                limit: 12,
                society: "",
                societyOptions: [],
                seal_id: '',
                sealOptions: [
                    //1公章 2合同章 3法人章 4其他
                    {
                        value: 1,
                        label: "公章",
                        disabled: false
                    },
                    {
                        value: 2,
                        label: "合同章",
                        disabled: false
                    }
                ],
            }
        },
        created() {
            this.$emit('setIndex', [5, 2])
            this.init()
            this.getSociety()
        },
        methods: {
            //  搜素
            search() {
                this.page = 1
                this.init()
            },
            timeChange() {
                this.page = 1
                this.init()
            },
            // 分页变化
            setPage(val) {
                this.page = val
                this.init()
            },
            getSociety() {
                // 获取学会列表
                this.$api.apiContent.getSociety({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.societyOptions = res.data
                }).catch(() => {
                    this.societyOptions = []
                })
            },
            tableExport() {
                let that = this
                that.$api.apiContent.table_xing_export({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    name: this.search_content,
                    sid: this.society,
                    seal_type: this.seal_id,
                    start_time: this.start_end_time && this.start_end_time.length > 0 ? this.start_end_time[0] : '',
                    end_time: this.start_end_time && this.start_end_time.length > 0 ? (this.start_end_time[1]+' 24:00') : '',
                    sign: that.$tools.SHA256({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        name: this.search_content,
                        sid: this.society,
                        seal_type: this.seal_id,
                        start_time: this.start_end_time && this.start_end_time.length > 0 ? this.start_end_time[0] : '',
                        end_time: this.start_end_time && this.start_end_time.length > 0 ? (this.start_end_time[1]+' 24:00') : '',
                    }, that.$store.state.user.key)
                }).then(res => {
                    window.location.href = config.urlStr + '/' + res.data
                }).catch((e) => {
                    that.$message.error(e.msg);
                })
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent.table_xing({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    page: this.page,
                    limit: this.limit,
                    name: this.search_content,
                    sid: this.society,
                    seal_type: this.seal_id,
                    start_time: this.start_end_time && this.start_end_time.length > 0 ? this.start_end_time[0] : '',
                    end_time: this.start_end_time && this.start_end_time.length > 0 ? (this.start_end_time[1]+' 24:00') : '',
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        name: this.search_content,
                        sid: this.society,
                        seal_type: this.seal_id,
                        start_time: this.start_end_time && this.start_end_time.length > 0 ? this.start_end_time[0] : '',
                        end_time: this.start_end_time && this.start_end_time.length > 0 ? (this.start_end_time[1]+' 24:00') : '',
                    }, this.$store.state.user.key)
                }).then(res => {
                    res.data.list.map(item => {
                        item.add_time = this.$tools.ZHDate(new Date(item.add_time * 1000), 1)
                        item.sp_time = this.$tools.ZHDate(new Date(item.sp_time * 1000), 1)
                    })
                    this.tableData = res.data.list
                    this.pageTotal = res.data.count
                }).catch(() => {
                    this.tableData = []
                })
            }
        },
        watch: {
            seal_id() {
                this.page = 1
                this.init()
            },
            society() {
                this.page = 1
                this.init()
            }
        }

    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }

            }
        }

        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }

            .project-list {
                padding: 34px 28px;
                min-height: 700px;

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-operate {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .el-button {
                        margin-left: 0px;
                        font-size: 16px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>